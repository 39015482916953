require('../assets/favicon.ico');
import '../sass/main.scss';
import Main from '@core/js/main';
class NJMain extends Main {
    constructor() {
        super();
    }
}

new NJMain();
